import React, { useState, useEffect, useRef } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { HOST_NAME } from "../const";
import ProgressBar from "@ramonak/react-progress-bar";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);

const SpiritualScore = () => {
  const [categoryScore, setCategoryScore] = useState(null);
  const [leverScore, setLeverScore] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const recommendation = [
    {
      Purpose: [
        {
          High: "Your sense of purpose is beautifully aligned with your career, and it shows! You’ve matched the organization’s strong focus on meaningful contributions, and your work reflects a deep understanding of your own significance and the impact you create. Keep pursuing this path, as you are truly fulfilling your potential and inspiring others to do the same.",
        },
        {
          Medium:
            "You're on the journey toward fully aligning your career with your life’s purpose. While you're making progress, there’s still room to find deeper fulfillment in what you do. By becoming more aware of your contributions and the impact you create, you’ll begin to unlock more of that inner sense of purpose. Keep going—you’re on the right path!",
        },
        {
          Low: "While your sense of purpose may feel distant, the journey to align your career with your life’s calling starts with small steps. Begin by reflecting on what truly matters to you and how your work can contribute to something greater. We’re here to support you as you explore new ways to find fulfillment in your career. The potential is within you, and every step brings you closer.",
        },
      ],
    },
    {
      Impact: [
        {
          High: "Your commitment to making a positive impact is truly inspiring! You’ve gone beyond the organization’s average by consistently contributing to the community and dedicating time and resources to those in need. Your selflessness and dedication set a shining example for everyone. Keep up the incredible work, as your efforts are creating lasting change.",
        },
        {
          Medium:
            "You are making strides in giving back and contributing to the community, but there’s more potential to tap into. By dedicating a little more time or resources, you can make an even bigger difference. Every act of kindness counts, and you’re on a meaningful path—keep building on it, and you’ll see even greater impact!",
        },
        {
          Low: "While your score in community impact may be low, remember that even the smallest efforts can create ripples of change. Consider starting with small acts of service or volunteering your time where it’s needed most. The opportunity to give back is always there, and we’re here to help you find ways to make a meaningful difference in both your life and the lives of others.",
        },
      ],
    },
  ];
  const [recData, setRecData] = useState();

  const slides = [
    <p className="slide">
      {categoryScore === null
        ? ""
        : categoryScore[0].Purpose >= 0 && categoryScore[0].Purpose <= 30
        ? recommendation[0].Purpose[2].Low
        : categoryScore[0].Purpose >= 31 && categoryScore[0].Purpose <= 70
        ? recommendation[0].Purpose[1].Medium
        : recommendation[0].Purpose[0].High}
    </p>,
    <p className="slide">
      {categoryScore === null
        ? ""
        : categoryScore[1].Impact >= 0 && categoryScore[1].Impact <= 30
        ? recommendation[1].Impact[2].Low
        : categoryScore[1].Impact >= 31 && categoryScore[1].Impact <= 70
        ? recommendation[1].Impact[1].Medium
        : recommendation[1].Impact[0].High}
    </p>,
  ];

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length); // Loop back to the first slide
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const chartRef = useRef(null);
  const [gradientColor, setGradientColor] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").filter(Boolean).pop();

    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);

      // Define the gradient (from transparent to #2860B5)
      gradient.addColorStop(0, "rgba(40, 96, 181, 0.3)"); // Transparent version
      gradient.addColorStop(1, "#2860B5"); // Fully opaque version

      setGradientColor(gradient); // Set the gradient color
    }

    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then(async (response) => {
          // setCategoryScore(res.data.data[0]["Physiological Health"].categories);
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const physiologicalHealth =
              response.data.data[1]["Spiritual Health"];
            if (physiologicalHealth && physiologicalHealth.categories) {
              setCategoryScore(physiologicalHealth.categories);
              setLeverScore(response.data.data[1]["Spiritual Health"].score);

              if (
                response.data.data[1]["Spiritual Health"].score > 0 &&
                response.data.data[1]["Spiritual Health"].score <= 30
              ) {
                setRecData(recommendation[2].Low);
              }
              if (
                response.data.data[1]["Spiritual Health"].score > 30 &&
                response.data.data[1]["Spiritual Health"].score <= 70
              ) {
                setRecData(recommendation[1].Medium);
              }
              if (
                response.data.data[1]["Spiritual Health"].score > 70 &&
                response.data.data[1]["Spiritual Health"].score <= 100
              ) {
                setRecData(recommendation[0].High);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    };
    fetchScore();

    const fetchResponse = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-res-data`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setResponseData(res.data.data.spiritual);
        })
        .catch((err) => console.log(err));
    };
    fetchResponse();
    const updateCount = async () => {
      await axios
        .post(`${HOST_NAME}/report/update-s1`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };
    updateCount();
  }, []);

  // const data = {
  //   datasets: [
  //     {
  //       data: [40, 60],
  //       backgroundColor: ["#278AFF", "#ccc"],
  //     },
  //   ],
  // };

  // const bardata = {
  //   labels: ["Well-being", "Movement", "Energy"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [50, 35, 34],
  //       borderRadius: 10,
  //       borderSkipped: false,
  //     },
  //   ],
  // };

  const colorData = {
    1: "orange",
    2: "orange",
    3: "gray",
    4: "green",
    5: "green",
  };

  // const bardata = {
  //   labels: ["Well-being"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [20, null],
  //       borderRadius: {
  //         topLeft: 0,
  //         topRight: 0,
  //         bottomLeft: 10,
  //         bottomRight: 10,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //     {
  //       backgroundColor: "#ccc",
  //       data: [80, null],
  //       borderRadius: {
  //         topLeft: 10,
  //         topRight: 10,
  //         bottomLeft: 0,
  //         bottomRight: 0,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: {
        display: function (context) {
          // Only display the data label for the blue bars
          return context.dataset.backgroundColor === "#2860B5";
        },
        color: "rgba(255,255,255,0.9)",
        align: "top",
        formatter: function (value, context) {
          // Only show the value for non-null values
          return value ? Math.ceil(parseInt(value)).toString() + "%" : "";
        },
      },
      tooltip: {
        enabled: false,
        // callbacks: {
        //   label: function (context) {
        //     let label = context.dataset.label || "";
        //     if (label) {
        //       label += ": ";
        //     }
        //     label += context.parsed.y + "%";
        //     return label;
        //   },
        // },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };

  // const options = {
  //   maintainAspectRatio: false,
  //   layout: {
  //     padding: 0,
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //     tooltip: {
  //       enabled: true,
  //       callbacks: {
  //         label: function (tooltipItem) {
  //           // Show tooltip only for the second dataset
  //           if (tooltipItem.datasetIndex === 0) {
  //             return `Value: ${tooltipItem.raw}`;
  //           }
  //           // Return null or empty string to skip tooltips for the first dataset
  //           return null;
  //         },
  //       },
  //     },
  //   },
  //   scales: {
  //     x: {
  //       display: true,

  //       grid: {
  //         display: false,
  //       },
  //     },
  //     y: {
  //       display: false,
  //     },
  //   },
  // };
  console.log(categoryScore);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "70%",
          // height: "auto",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "rgba(255,255,255,0.5)",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            width: "87%",
            fontWeight: "600",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          <span style={{ fontSize: "17px" }}>Spiritual Health</span> assesses
          the alignment of individual purpose and their inclination to a larger
          societal mission
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "180px", height: "180px", marginLeft: "50px" }}>
            <p style={{ position: "relative", left: "150px", top: "20px" }}>
              *{" "}
              {leverScore >= 0 && leverScore <= 30
                ? "Struggling"
                : leverScore >= 30 && leverScore <= 70
                ? "Progressing"
                : "Excelling"}
            </p>
            <Doughnut
              ref={chartRef}
              data={{
                datasets: [
                  {
                    data:
                      leverScore !== null
                        ? [leverScore, 100 - leverScore]
                        : [0, 100],
                    backgroundColor: [gradientColor, "rgba(0,0,0,0.1)"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                rotation: 0,
                circumference: 360,
                cutout: "75%",
                maintainAspectRatio: true,
                responsive: true,
              }}
            />
            <p
              style={{
                fontWeight: "400",
                fontSize: "18px",
                color: "#2961B6",
                marginTop: "-110px",
                marginLeft: "70px",
              }}
            >
              Score <br /> {Math.ceil(leverScore)}%
            </p>
          </div>

          <div
            className={{
              maxWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "250px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {categoryScore &&
              Array.isArray(categoryScore) &&
              categoryScore.length > 0 ? (
                categoryScore.map((score) => (
                  <Bar
                    style={{
                      height: "250px",
                      width: "250px",
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: Object.keys(score),
                      datasets: [
                        {
                          backgroundColor: "#2860B5",
                          data: [Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                        {
                          backgroundColor: "rgba(0,0,0,0)",
                          data: [100 - Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 0,
                            bottomRight: 0,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                      ],
                    }}
                    options={options}
                  />
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "50%" }}>
            <p className="font-bold text-xl">Purpose</p>
            <p>My work gives me energy rather than draining out energy</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[0])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[0]) : 0
              }
            />
            <p>I am aware of my purpose in both my life and career</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[1])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[1]) : 0
              }
            />
            <p className="font-bold text-xl">Impact</p>
            <p>My Organization is a socially responsible Company</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[2])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[2]) : 0
              }
            />
            <p>
              I receive sufficient opportunities at work to contribute to social
              causes
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[3])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[3]) : 0
              }
            />
            <p>
              My work and its impact on others give me a sense of fulfillment
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
            <p>
              I have a mindfulness practice that I incorporate into my routine
              to support my well-being
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
          </div>
          <div
            className="slider-container"
            id="recommendation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="slider"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`slider-item ${
                    index === currentSlide ? "active" : ""
                  }`}
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                  }}
                >
                  {slide}
                </div>
              ))}
            </div>
            <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={goToPreviousSlide}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                  fontSize: "30px",
                  border: "1px solid #000",
                  padding: "5px",
                  borderRadius: "10px",
                  height: "50px",
                  width: "50px",
                }}
              >
                {"<"}
              </button>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "30px",
                  border: "1px solid #000",
                  padding: "5px",
                  borderRadius: "10px",
                  height: "50px",
                  width: "50px",
                }}
                onClick={goToNextSlide}
              >
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SpiritualScore;
