import React, { useState } from "react";
// import FinancialInference from "../components/FinancialInference";
import FinancialInitiative from "../components/FinancialInitiative";
import FinancialScore from "../components/FinancialScore";

const Financial = () => {
  const [activeTab, setActiveTab] = useState("score");
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  const renderContent = () => {
    switch (activeTab) {
      case "score":
        return <FinancialScore />;

      case "initiative":
        return <FinancialInitiative />;
      default:
        return <FinancialScore />;
    }
  };

  return (
    <div className="w-full flex justify-center content-center flex-col">
      <div className="flex justify-center content-center">
        <div className="w-3/5 p-3 mt-3 bg-[rgba(40,96,181,0.2)] rounded-lg">
          <div className="flex justify-evenly content-center flex-row">
            <div
              className={` px-4 py-2 text-sm font-medium cursor-pointer ${
                activeTab === "score"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
              onClick={() => handleTabClick("score")}
            >
              <span>Your Report</span>
            </div>

            <div
              className={` px-4 py-2 text-sm font-medium cursor-pointer ${
                activeTab === "initiative"
                  ? "border-b-2 border-blue-500 text-blue-500"
                  : "text-gray-600"
              }`}
              onClick={() => handleTabClick("initiative")}
            >
              <span>Action Blueprint</span>
            </div>
          </div>
        </div>
      </div>
      {renderContent()}
    </div>
  );
};

export default Financial;
