import React, { useState, useEffect } from "react";
import initiativeCont from "./initiativesContent";
import { HOST_NAME } from "../const";
import axios from "axios";
import { useParams } from "react-router-dom";

const FinancialInitiative = () => {
  const [trivia, setTrivia] = useState(null);
  const [apps, setApps] = useState(null);
  const [hints, setHints] = useState(null);
  const [books, setBooks] = useState(null);
  const [podcasts, setPodcasts] = useState(null);
  const { lastSegment } = useParams();
  useEffect(() => {
    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then(async (response) => {
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const physiologicalHealth =
              response.data.data[2]["Financial Health"];
            if (physiologicalHealth && physiologicalHealth.categories) {
              if (
                response.data.data[2]["Financial Health"].score > 70 &&
                response.data.data[2]["Financial Health"].score <= 100
              ) {
                setTrivia(initiativeCont[2]["Financial Health"][0].Trivia);
                setApps(initiativeCont[2]["Financial Health"][0].Apps);
                setHints(initiativeCont[2]["Financial Health"][0].Hints);
                setBooks(initiativeCont[2]["Financial Health"][0].Books);
                setPodcasts(initiativeCont[2]["Financial Health"][0].Podcasts);
              }

              if (
                response.data.data[2]["Financial Health"].score > 30 &&
                response.data.data[2]["Financial Health"].score <= 70
              ) {
                setTrivia(initiativeCont[2]["Financial Health"][1].Trivia);
                setApps(initiativeCont[2]["Financial Health"][1].Apps);
                setHints(initiativeCont[2]["Financial Health"][1].Hints);
                setBooks(initiativeCont[2]["Financial Health"][1].Books);
                setPodcasts(initiativeCont[2]["Financial Health"][1].Podcasts);
              }
              if (
                response.data.data[2]["Financial Health"].score > 0 &&
                response.data.data[2]["Financial Health"].score <= 30
              ) {
                setTrivia(initiativeCont[2]["Financial Health"][2].Trivia);
                setApps(initiativeCont[2]["Financial Health"][2].Apps);
                setHints(initiativeCont[2]["Financial Health"][2].Hints);
                setBooks(initiativeCont[2]["Financial Health"][2].Books);
                setPodcasts(initiativeCont[2]["Financial Health"][2].Podcasts);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    };
    fetchScore();
    const updateCount = async () => {
      await axios
        .post(`${HOST_NAME}/report/update-f2`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };
    updateCount();
  }, []);

  return (
    <div className="w-11/12 flex justify-center content-center m-8 p-4 border-2 bg-[rgba(255,255,255,0.6)] rounded-xl">
      <div className="w-3/4 flex flex-col">
        <span
          style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)" }}
          className="font-bold text-center"
        >
          {trivia}
        </span>
        <span className="font-bold mt-5">Hints and hacks we prescribe...</span>
        {hints !== null &&
          hints.map((h) => <span className="mt-5">{h.data}</span>)}
        <span className="font-bold mt-5">
          Apps are everywhere! We have a few suggestions to make it easy on
          you...
        </span>
        {apps !== null &&
          apps.map((a) => <span className="mt-5">{a.data}</span>)}
        <span className="font-bold mt-5">
          Podcasts - Expert insights, anytime—tune into engaging audio shows!
        </span>
        <span className="mt-5">{podcasts}</span>
        <span className="font-bold mt-5">
          Books - Read to lead—unlock your potential and grow!
        </span>
        <span className="mt-5">{books}</span>
        <span className="font-bold mt-5">
          Compound your daily micro actions towards an identity
        </span>
        <span className="mt-5">
          You are provided with Tesa, An AI growth partner from Aspire. Tesa
          helps you to curate habit of your own towards a competency you wish to
          build for a holistic self.
        </span>
      </div>
    </div>
  );
};

export default FinancialInitiative;
