import React, { useState, useEffect, useRef } from "react";
import { Doughnut, Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
import axios from "axios";
import { HOST_NAME } from "../const";
import ProgressBar from "@ramonak/react-progress-bar";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(...registerables);

const FinancialScore = () => {
  const [categoryScore, setCategoryScore] = useState(null);
  const [leverScore, setLeverScore] = useState(null);
  const [responseData, setResponseData] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);

  const recommendation = [
    {
      "Status quo": [
        {
          High: "Your financial management is exceptional! You’re surpassing the organization’s average by diligently managing your finances through strategic savings, smart investments, and debt control. You’ve built a solid foundation, and your efforts reflect strong financial awareness. Keep up the great work—your financial discipline sets a powerful example for others!",
        },
        {
          Medium:
            "You're on the right track with your finances. You're putting in good effort with savings and investments, but there's more potential to strengthen your financial stability. Consider monitoring your expenses more closely and perhaps enhancing your savings plan or debt management strategies. You’re building a solid foundation—just a few adjustments will take you further!",
        },
        {
          Low: "Though your financial score is currently low, every small step toward better financial planning can make a big difference. Start with simple actions like creating a budget, tracking your expenses, and building an emergency fund. Your financial future is in your hands, and we're here to support you as you begin this journey toward stability.",
        },
      ],
    },
  ];

  const [recData, setRecData] = useState();

  const slides = [
    <p className="slide">
      {categoryScore === null
        ? ""
        : categoryScore[0]["Status quo"] >= 0 &&
          categoryScore[0].Wellbeing <= 30
        ? recommendation[0]["Status quo"][2].Low
        : categoryScore[0]["Status quo"] >= 31 &&
          categoryScore[0]["Status quo"] <= 70
        ? recommendation[0]["Status quo"][1].Medium
        : recommendation[0]["Status quo"][0].High}
    </p>,
    // <p className="slide">
    //   {categoryScore === null
    //     ? ""
    //     : categoryScore[1].Movement >= 0 && categoryScore[1].Movement <= 30
    //     ? recommendation[1].movement[2].Low
    //     : categoryScore[1].Movement >= 31 && categoryScore[1].Movement <= 70
    //     ? recommendation[1].movement[1].Medium
    //     : recommendation[1].movement[0].High}
    // </p>,
    // <p className="slide">
    //   {categoryScore === null
    //     ? ""
    //     : categoryScore[2].Energy >= 0 && categoryScore[2].Energy <= 30
    //     ? recommendation[2].energy[2].Low
    //     : categoryScore[2].Energy >= 31 && categoryScore[2].Energy <= 70
    //     ? recommendation[2].energy[1].Medium
    //     : recommendation[2].energy[0].High}
    // </p>,
  ];

  const goToNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length); // Loop back to the first slide
  };

  const goToPreviousSlide = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1
    );
  };

  const chartRef = useRef(null);
  const [gradientColor, setGradientColor] = useState(null);

  useEffect(() => {
    const path = window.location.pathname;
    const lastSegment = path.split("/").filter(Boolean).pop();

    const chart = chartRef.current;
    if (chart) {
      const ctx = chart.ctx;
      const gradient = ctx.createLinearGradient(0, 0, 0, 200);

      // Define the gradient (from transparent to #2860B5)
      gradient.addColorStop(0, "rgba(40, 96, 181, 0.3)"); // Transparent version
      gradient.addColorStop(1, "#2860B5"); // Fully opaque version

      setGradientColor(gradient); // Set the gradient color
    }

    const fetchScore = async () => {
      await axios
        .post(`${HOST_NAME}/report/score-uuid`, {
          uuid: lastSegment,
        })
        .then(async (response) => {
          // setCategoryScore(res.data.data[0]["Physiological Health"].categories);
          if (
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            const physiologicalHealth =
              response.data.data[2]["Financial Health"];
            if (physiologicalHealth && physiologicalHealth.categories) {
              setCategoryScore(physiologicalHealth.categories);
              setLeverScore(response.data.data[2]["Financial Health"].score);

              if (
                response.data.data[2]["Financial Health"].score > 0 &&
                response.data.data[2]["Financial Health"].score <= 30
              ) {
                setRecData(recommendation[2].Low);
              }
              if (
                response.data.data[2]["Financial Health"].score > 30 &&
                response.data.data[2]["Financial Health"].score <= 70
              ) {
                setRecData(recommendation[1].Medium);
              }
              if (
                response.data.data[2]["Financial Health"].score > 70 &&
                response.data.data[2]["Financial Health"].score <= 100
              ) {
                setRecData(recommendation[0].High);
              }
            }
          }
        })
        .catch((err) => console.log(err));
    };
    fetchScore();

    const fetchResponse = async () => {
      await axios
        .post(`${HOST_NAME}/report/get-ind-res-data`, {
          uuid: lastSegment,
        })
        .then((res) => {
          setResponseData(res.data.data.financial);
        })
        .catch((err) => console.log(err));
    };
    fetchResponse();
    const updateCount = async () => {
      await axios
        .post(`${HOST_NAME}/report/update-f1`, {
          uuid: lastSegment,
        })
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => console.log(err));
    };
    updateCount();
  }, []);

  // const data = {
  //   datasets: [
  //     {
  //       data: [40, 60],
  //       backgroundColor: ["#278AFF", "#ccc"],
  //     },
  //   ],
  // };

  // const bardata = {
  //   labels: ["Well-being", "Movement", "Energy"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [50, 35, 34],
  //       borderRadius: 10,
  //       borderSkipped: false,
  //     },
  //   ],
  // };

  const colorData = {
    1: "orange",
    2: "orange",
    3: "gray",
    4: "green",
    5: "green",
  };

  // const bardata = {
  //   labels: ["Well-being"],
  //   datasets: [
  //     {
  //       backgroundColor: "#2860B5",
  //       data: [20, null],
  //       borderRadius: {
  //         topLeft: 0,
  //         topRight: 0,
  //         bottomLeft: 10,
  //         bottomRight: 10,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //     {
  //       backgroundColor: "#ccc",
  //       data: [80, null],
  //       borderRadius: {
  //         topLeft: 10,
  //         topRight: 10,
  //         bottomLeft: 0,
  //         bottomRight: 0,
  //       },
  //       borderSkipped: false,
  //       barThickness: 50,
  //       stack: "Stack 0",
  //     },
  //   ],
  // };

  const options = {
    maintainAspectRatio: false,
    layout: {
      padding: 0,
    },
    plugins: {
      datalabels: {
        display: function (context) {
          // Only display the data label for the blue bars
          return context.dataset.backgroundColor === "#2860B5";
        },
        color: "rgba(255,255,255,0.9)",
        align: "top",
        formatter: function (value, context) {
          // Only show the value for non-null values
          return value ? Math.ceil(parseInt(value)).toString() + "%" : "";
        },
      },
      tooltip: {
        enabled: false,
        // callbacks: {
        //   label: function (context) {
        //     let label = context.dataset.label || "";
        //     if (label) {
        //       label += ": ";
        //     }
        //     label += context.parsed.y + "%";
        //     return label;
        //   },
        // },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        display: true,
        grid: {
          display: false,
        },
      },
      y: {
        display: false,
      },
    },
  };

  // const options = {
  //   maintainAspectRatio: false,
  //   layout: {
  //     padding: 0,
  //   },
  //   plugins: {
  //     legend: {
  //       display: false,
  //     },
  //   },
  //   scales: {
  //     x: {
  //       display: true,

  //       grid: {
  //         display: false,
  //       },
  //     },
  //     y: {
  //       display: false,
  //     },
  //   },
  // };
  console.log(categoryScore);
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // flexDirection: "column",
        // overflowY: "scroll",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: "50px",
          width: "70%",
          // height: "auto",
          padding: "30px",
          borderRadius: "12px",
          backgroundColor: "rgba(255,255,255,0.5)",
          flexDirection: "column",
        }}
      >
        <p
          style={{
            width: "87%",
            fontWeight: "600",
            textShadow: "2px 2px 4px rgba(0, 0, 0, 0.3)",
          }}
        >
          <span style={{ fontSize: "17px" }}>Financial Health</span> examines
          employees' financial awareness of the status quo and efforts towards
          economic stability.
        </p>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "180px", height: "180px", marginLeft: "50px" }}>
            <p style={{ position: "relative", left: "150px", top: "20px" }}>
              *{" "}
              {leverScore >= 0 && leverScore <= 30
                ? "Struggling"
                : leverScore >= 30 && leverScore <= 70
                ? "Progressing"
                : "Excelling"}
            </p>
            <Doughnut
              ref={chartRef}
              data={{
                datasets: [
                  {
                    data:
                      leverScore !== null
                        ? [leverScore, 100 - leverScore]
                        : [0, 100],
                    backgroundColor: [gradientColor, "rgba(0,0,0,0.1)"],
                  },
                ],
              }}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    enabled: true,
                  },
                },
                rotation: 0,
                circumference: 360,
                cutout: "75%",
                maintainAspectRatio: true,
                responsive: true,
              }}
            />
            <p
              style={{
                fontWeight: "400",
                fontSize: "18px",
                color: "#2961B6",
                marginTop: "-110px",
                marginLeft: "70px",
              }}
            >
              Score <br /> {Math.ceil(leverScore)}%
            </p>
          </div>

          <div
            className={{
              maxWidth: "250px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <div
              style={{
                width: "100px",
                height: "250px",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {categoryScore &&
              Array.isArray(categoryScore) &&
              categoryScore.length > 0 ? (
                categoryScore.map((score) => (
                  <Bar
                    style={{
                      height: "250px",
                      width: "250px",
                    }}
                    plugins={[ChartDataLabels]}
                    data={{
                      labels: Object.keys(score),
                      datasets: [
                        {
                          backgroundColor: "#2860B5",
                          data: [Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 10,
                            bottomRight: 10,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                        {
                          backgroundColor: "rgba(0,0,0,0)",
                          data: [100 - Object.values(score)[0], null],
                          borderRadius: {
                            topLeft: 10,
                            topRight: 10,
                            bottomLeft: 0,
                            bottomRight: 0,
                          },
                          borderSkipped: false,
                          barThickness: 50,
                          stack: "Stack 0",
                        },
                      ],
                    }}
                    options={options}
                  />
                ))
              ) : (
                <div>No data available</div>
              )}
            </div>
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "40px",
          }}
        >
          <div style={{ width: "50%" }}>
            {/* <p className="font-bold text-xl">Well-being</p> */}
            <p>My current salary aligns well with my financial needs</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[0])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[0]) : 0
              }
            />
            <p>I contribute to emergency funds along with my savings</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[1])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[1]) : 0
              }
            />
            <p>I am the single source of income for my family</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[2])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[2]) : 0
              }
            />
            <p>My Company supports me during times of need</p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[3])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[3]) : 0
              }
            />
            <p>
              There are adequate schemes available here for my financial
              support, safety & well-being
            </p>
            <ProgressBar
              className="w-11/12 m-2"
              height="12px"
              bgColor={
                responseData !== null ? colorData[parseInt(responseData[4])] : 1
              }
              completed={
                responseData !== null ? 20 * parseInt(responseData[4]) : 0
              }
            />
          </div>
          <div
            className="slider-container"
            id="recommendation"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div
              className="slider"
              style={{
                width: "100%",
                padding: "20px",
                borderRadius: "10px",
              }}
            >
              {slides.map((slide, index) => (
                <div
                  key={index}
                  className={`slider-item ${
                    index === currentSlide ? "active" : ""
                  }`}
                  style={{
                    transform: `translateX(-${currentSlide * 100}%)`,
                  }}
                >
                  {slide}
                </div>
              ))}
            </div>
            {/* <div
              style={{
                marginTop: "15px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <button
                onClick={goToPreviousSlide}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginRight: "20px",
                  fontSize: "30px",
                  border: "1px solid #000",
                  padding: "5px",
                  borderRadius: "10px",
                  height: "50px",
                  width: "50px",
                }}
              >
                {"<"}
              </button>
              <button
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "30px",
                  border: "1px solid #000",
                  padding: "5px",
                  borderRadius: "10px",
                  height: "50px",
                  width: "50px",
                }}
                onClick={goToNextSlide}
              >
                {">"}
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FinancialScore;
